<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div id="map"></div>
            <div class="location_info fx-middle">
                <span>{{name}}</span>
                <p>{{address}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            map: null,
            marker: null,
            name: this.$route.query.name,
            address: this.$route.query.address,
        }
    },
    mounted() {
        this.$documentTitle(this.name)
        this.buildMap();
    },
    methods: {
        buildMap() {
            let lon = this.$route.query.lon,
                lat = this.$route.query.lat;
            this.map = new AMap.Map('map', {
                zoom: 19,
                resizeEnable: true,
                center: [lon, lat]
            });

            let icon = new AMap.Icon({
                size: new AMap.Size(36, 62),
                image: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_bs.png',
                imageSize: new AMap.Size(36, 62)
            })

            this.marker = new AMap.Marker({
                map: this.map,
                position: [lon, lat],
                icon: icon
            });

            this.marker.on('click', function (e) {
                console.log(e);
                this.markOnAMAP({
                    position: this.getPosition(),
                })
            });


            let that = this
            AMap.plugin(
                [
                    "AMap.ToolBar",
                    "AMap.Geolocation",
                ],
                function () {
                    // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
                    that.map.addControl(new AMap.ToolBar());

                    // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
                    that.map.addControl(new AMap.Geolocation());
                }
            );
        }
    }
}
</script>

<style lang="scss" scoped>
#map {
	width: 100%;
	height: calc(100vh - 80px);
}
.location_info {
	width: 100%;
	height: 80px;
	background: #fff;
	padding: 0 15px;
    border:1px solid $border-color;
	span {
		font-size: 16px;
	}
	p {
		font-size: 12px;
		color: #999;
	}
}
</style>